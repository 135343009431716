.checkbox_container {
  width: 180px;
  height: 48px;

  background: $dullwhite;
  border: 1px solid $outline;
  border-radius: 10px;
  padding: 0 16px;
  margin-top: 4px;
  margin-right: 24px;
  position: relative;
  z-index: 10;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  }
  &.open {
    border-color: $green;
  }
  .checkbox_control_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    &.placeholder {
      letter-spacing: 0.25px;
      color: #777774;
      .title {
        color: inherit;
      }
    }
    .title {
      margin: 0 16px 0 0 !important;
    }
  }
  .checkbox_body {
    width: 232px;
    height: 375px;
    position: absolute;
    top: 60px;
    left: 0;

    background: $dullwhite;
    border: 1px solid $outline;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    .checkbox_search {
      width: auto;
      margin: 0 8px;
      border-bottom: 1px solid $outline;
      .MuiInputBase-root {
        background: $dullwhite;
        border: none;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        box-shadow: none;
      }
    }
    .wrapper {
      padding: 0 2px;
      .empty {
        color: $grey;
        text-align: center;
        margin-top: 20px;
      }
      .scroll {
        &::-webkit-scrollbar {
          width: 3px;
        }
        
        &::-webkit-scrollbar-thumb {
          background: $green; 
          border-radius: 2px 0px 0px 2px;
        }
      }
      .checkbox_item {
        width: 100%;
        margin: 0;
        padding: 5px;
        border-radius: 14px;
        &:hover {
          background: $neutral;
        }
        .MuiFormControlLabel-label {
          display: inline-block;
          width: 150px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .button_wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      .reset_button {
        width: calc(100% - 16px);
        height: 62px;
        margin: 0 8px;
        border: none;
        color: $error;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: 1px solid $outline;
        &:hover {
          background: transparent;
        }
      }
    }
  }
}