.modal_qr_code_container {
  .modal_wrap {
    width: 486px;
    .title {
      margin: 24px 0;
    }
    .logo {
      width: 120px;
    }
    .list {
      margin-bottom: 40px;
      .item_list {
        display: block;
        margin-bottom: 12px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .link {
      margin-top: 8px;
      .span {
        color: $green;
        text-decoration: none;
      }
    }
    .center {
      width: 100%;
      display: block;
      text-align: center;
    }
  }
  .qr_code_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 146px;
    height: 146px;
    background: $whiteprimary;
    border: 1px solid $green;
    border-radius: 13px;
    margin-bottom: 24px;
    position: relative;
    .complete_icon {
      position: absolute;
      font-size: 58px;
      color: green;
      animation: completeAnim 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      &.cancel_icon {
        color: $error;
      }
    }
    .qr_code_wrap {
      width: 85px;
      height: 85px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.scanned {
        background-color: rgba($color: $whiteprimary, $alpha: 0.5);
        filter: blur(16px);
        transition: all 0.6s;
      }
    }
    .scanned_loader {
      position: absolute;
    }
  }
  .conection_line {
    padding: 32px 0;
    width: 100%;
    display: flex;
    align-items: center;
    .or {
      margin: 0 16px;
      color: $outline;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
    .line {
      flex: 1;
      border-top: 1px solid $outline;
    }
  }
}

.mobile_modal_qr_code_container {
  .modal_message {
    text-transform: inherit;
    font-weight: 400;
    text-align: center;
  }
  .bankId_button {
    width: 270px;
  }
  .mobile {
    color: $white;
    margin-top: 20px;
  }
}

@keyframes completeAnim {
  from {
    font-size: 0px;
  }
  to {
    font-size: 58px;
  }
}
