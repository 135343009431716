.agent_previous_meeting_page {
  padding: 0 0 150px;
  .back_upcoming_meeting {
    padding-top: 30px;
    margin-bottom: 25px;
  }
  .page_title {
    margin-bottom: 30px;
  }
  .table_section {
    margin-bottom: 24px;
    .customer_table_cell {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .name {
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .email {
        color: $greyplaceholder;
      }
    }
  }
  .show_more_button {
    width: auto;
    align-self: baseline;
  }
}

.agent_previous_meeting_page.mobile_container {
  padding: 24px 0 40px;
  .back_upcoming_meeting {
    margin-bottom: 15px;
  }
  .go_back_button_container {
    padding: 0 16px;
    button {
      padding: 0;
      text-transform: uppercase;
    }
  }
  .page_title {
    font-size: 20px;
    font-weight: 500;
    padding: 0 16px;
  }

  .item_section {
    .date_wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 25px;
      border-top: 1px solid $outline;
      border-bottom: 1px solid $outline;
      .title {
        text-transform: capitalize;
      }
      
      .navigate_button_container {
        border: none;
        background: transparent;
      }
      .navigate_button {
        color: $black;
        transform: scale(1.5);
      }
    }
    .data_wrapper {
      padding: 24px 16px;
      .section {
        width: 100%;
        height: 56px;
        display: flex;
        align-items: center;
        background-color: $white;
        padding: 16px;
        border: 1px solid $outline;
        border-radius: 10px;
        margin-bottom: 8px;
        .name {
          width: 40%;
          color: $green;
          padding-left: 16px;
        }
        .city {
          width: 35%;
          line-height: 18px;
        }
        .value {
          width: 60%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          border-right: 1px solid $outline;
        }
      }
    }
  }
}
