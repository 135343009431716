.avatar_container {
  width: 88px;
  min-width: 88px;
  height: 88px;
  border-radius: 50%;
  overflow: hidden;
  background-color: $neutralsecond;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .avatar_img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}


.avatar_container.mobile {
  width: 116px;
  height: 116px;
}
