// .client_general_profile_form {
//   display: flex;
//   flex-direction: column;
//   align-items: baseline;
//   width: 100%;
//   max-width: 623px;
//   .form_row {
//     width: 100%;
//     display: flex;
//     margin-bottom: 32px;

//     > :first-child {
//       margin-right: 16px;
//     }
//     > :last-child {
//       margin-right: 0;
//     }
//     &.with_helper_text {
//       margin-bottom: 56px;
//     }
//   }
// }

.client_general_profile_form.mobile {
  .form_row {
    margin: 16px 0 0;
  }
  .phone {
    margin-bottom: 24px;
  }
  .name {
    flex-direction: column;
    > :first-child {
      margin-bottom: 16px;
    }
  }
}
