.panels_container {
  display: flex;
  flex-direction: column;
}

.tabs_buttons_container {
  display: flex;
  align-items: center;
  position: relative;
  bottom: -1px;
  width: auto;
}

.tab_button {
  cursor: pointer;
  border: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  margin-right: 17px;
  width: calc(50% - 35px);
  height: 50px;
  background: #e2d1af;
  filter: drop-shadow(2px 0px 2px rgba(0, 0, 0, 0.2));
  &:last-child {
    margin-right: 0;
  }
  &:first-child {
    filter: drop-shadow(4px 0px 2px rgba(0, 0, 0, 0.2));
  }
  &.active {
    background: $delimiter;
    z-index: 10;
    &::after {
      border-color: transparent transparent transparent $delimiter;
      z-index: inherit;
    }
    .panel_button_text {
      color: $green;
    }
  }
  &::after {
    position: absolute;
    bottom: 0px;
    left: calc(100% - 3px);
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 47px 0 0 35px;
    border-color: transparent transparent transparent #e2d1af;
    z-index: inherit;
    transform: rotate(0deg);
  }
  .panel_button_text {
    margin-left: 17px;
    color: rgba($color: $black, $alpha: 0.8);
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    font-style: inherit;
  }
  &:disabled {
    cursor: default;
  }
}

.content_block_container {
  position: relative;
  z-index: 11;
  display: flex;
  flex-direction: column;
  // align-items: center; /// delete
  // justify-content: center; /// delete
  // width: 476px; /// delete
  // height: 490px; /// delete
  padding: 48px 32px 32px;
  background: $delimiter;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 20px 20px 20px;
}

.mobile_tabs_buttons_container {
  width: 100%;
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba($color: $black, $alpha: 0.08);
  border-radius: 10px;
  margin: 0 auto 32px;
  .mobile_tab_button {
    width: 50% !important;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
  }
  .active {
    background: rgba($color: $black, $alpha: 0.8);
    border-radius: 9px;
  }
  .active-text {
    color: $white !important;
  }
}