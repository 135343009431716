.agent_booking_list_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 150px;
  .agent_booking_list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1130px;
    padding: 40px 0 0;
    .top_section {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 33px;
      .title_block {
        display: flex;
        flex-direction: column;
        width: 60%;
      }
      .title {
        margin-bottom: 8px;
      }
      .filters_container {
        display: flex;
        .sort_filter {
          &:first-of-type {
            margin-right: 24px;
          }
        }
      }
      .noPlaceholder {
        .end_adornment_wrap {
          margin: 0 15px;
        }
      }
    }
    .selected_agents_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      .send_agents_text {
        margin-right: 10px;
      }
    }
    .selected_agents_count_container {
      display: flex;
      align-items: center;
      .count_container {
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 25px;
        svg {
          width: 40px;
          height: 40px;
        }
        .count {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          width: 32px;
          height: 32px;
          background-color: $blue;
          position: absolute;
          top: -12px;
          right: -18px;

          color: $white;
        }
      }
    }
    
    .empty_message {
      width: 100%;
      text-align: center;
      margin-top: 30px;
    }
    .agents_list_container {
      width: auto;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 12px;
      margin-left: -7px;
      margin-right: -7px;
      .agent_item_container {
        width: 25%;
        min-width: 284px;
        height: auto;
        display: flex;
        align-items: stretch;
        justify-content: center;
      }
      .agent_item_wrap {
        display: flex;
        flex-direction: column;
        width: 270px;
        background: $whiteprimary;
        border: 1px solid $outline;
        border-radius: 16px;
        margin-bottom: 20px;
        overflow: hidden;
        position: relative;
        .agent_selected_checkbox_container {
          z-index: 2;
          position: absolute;
          top: 16px;
          right: 16px;
          cursor: pointer;

          display: flex;
          justify-content: center;
          align-items: center;

          background-color: $main;
          padding: 4px 8px;
          border-radius: 10px;
          .checkbox_tooltip_container {
            width: 238px;
            position: absolute;
            top: 40px;
            right: 0;
            border-radius: 8px;
            background:rgba(28, 28, 23, 0.80);
            padding: 8px 16px;
            box-sizing: border-box;
            .tooltip_text {
              display: block;
              color: #FFFFFE;
              text-align: center;
              font-size: 14px;
            }
          }
        }
        .foto_container {
          width: 100%;
          height: 281px;
          position: relative;
          z-index: 1;
          cursor: pointer;
          .expert_label_container {
            position: absolute;
            top: 16px;
            left: 16px;
          }
          
          .rate_container {
            position: absolute;
            right: 16px;
            bottom: 16px;
            border-radius: 10px;
            background: #FFF1D5;
            padding: 4px 6px;
            display: flex;
            align-items: center;
            z-index: 1;
            .rate_text {
              color:rgba(28, 28, 23, 0.80);
              font-size: 14px;
              margin-left: 3px;
            }

            .rate_tooltip_container {
              position: absolute;
              right: 0;
              bottom: calc(100% + 7px);
              padding: 8px 16px;
              width: 245px;
              box-sizing: border-box;
              border-radius: 8px;
              background:rgba(28, 28, 23, 0.80);
              .rate_tooltip_text {
                display: block;
                color: #FFFFFE;
                text-align: center;
                font-size: 14px;
              }
            }
          }
          .foto_blured_container {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 62px;
            background: linear-gradient(180deg, rgba(28, 28, 23, 0.00) 0%, #1C1C17 100%);
          }
          .foto {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
          }
          .no_logo_icon {
            font-size: 76px;
            color: rgba($color: $black, $alpha: 0.5);
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .select_first_timeslot_button {
          margin-bottom: 15px;
        }
        .first_timeslot_text {
          letter-spacing: 0.5px;
          font-weight: 700;
          margin-right: 8px;
          color: $black;
        }
        .bottom_section {
          display: flex;
          flex-direction: column;
          padding: 16px 16px 24px;
          flex: 1;
          .name {
            margin-bottom: 8px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          .agency_logo_container {
            width: 100%;
            height: 26px;
            margin-bottom: 16px;
            .agency_logo {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: left;
              mix-blend-mode: multiply;
            }
          }
          // .description {
          //   max-height: 110px;
          //   display: -webkit-box;
          //   -webkit-line-clamp: 5;
          //   -webkit-box-orient: vertical;
          //   overflow: hidden;
          //   margin-bottom: 12px;
          //   font-weight: 600;
          //   color: $black;
          // }
          .statistic_container {
            &:first-of-type {
              margin-bottom: 12px;
            }
            &:last-of-type {
              margin-bottom: 16px;
            }
            .statistic_value {
              color: $black;
              font-family: Open Sans;
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              letter-spacing: 0.15px;
            }
    
            .statistic_description {
              color: $black;
              font-family: Open Sans;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.15px;
            }
          }
          .buttons_container {
            margin-top: auto;
            button {
              width: 100%;
              &:first-of-type {
                margin-bottom: 17px;
              }
            }
          }
        }
      }
    }
    .show_more_button {
      align-self: center;
    }
  }
}

.agent_booking_list_container.mobile_container {
  padding: 0 16px 32px;
  .top_section {
    flex-direction: column;
    .filters_container {
      flex-direction: column;
      width: 100%;
      padding-bottom: 16px;
      border-bottom: 1px solid $outline;
      .checkbox_container,
      .select_container {
        width: 100%;
      }
      .checkbox_container {
        order: 1;
        .title,
        .checkbox_body{
          width: 100%;
        }
      }
      .select_container {
        margin-bottom: 16px;
      }
      .sort_filter {
        &:first-of-type {
          margin-right: 0;
        }
      }
    }
    .title_block {
      width: 100%;
    }
    .select_container {
      margin-top: 24px;
    }
  }
  .selected_agents_header {
    flex-direction: column;
    .selected_agents_count_container {
      width: 100%;
      justify-content: flex-end;
      margin-top: 20px;
      padding-right: 10px;
    }
    .count {
      font-size: 16px;
    }
    .count_container {
      order: 1;
      margin-right: 0;
      margin-left: 15px;
    }
    .selected_agents_title {
      display: flex;
      order: 0;
      font-size: 20px;
    }
  }
  .empty_message {
    margin: 0;
  }
  .agents_list_container {
    .agent_item_container {
      width: 100%;
      max-width: 400px;
      .agent_item_wrap {
        width: 100%;
        .foto_container { 
          height: 340px;
          border-bottom: 1px solid $outline;
          .foto {
            object-fit: contain;
            object-position: center;
          }
        }
        .bottom_section {
          .agency_logo_container {
            .agency_logo {
              object-fit: contain;
            }
          }
        }
        .buttons_container {
          .button_schedule {
            font-size: 14px;
          }
          .agent_detail_button {
            width: 48px;
            height: 42px;
            svg {
              transform: scale(0.5);
            }
          }
        }
      }
    }
  }
}
