$green: #096a5e;
$darkGreen: #235971;
$black: #1c1c17;
$grey: #1c1c17cc;
$lightGrey: #1c1c17cc;
$greyplaceholder: #1c1c1799;
$outline: #d7d3d0;
$white: #ffffff;
$whiteprimary: #fffffe;
$dullwhite: #fffefb;
$neutral: #f3f0e9;
$neutralsecond: #d8dcdc;
$main: #fff9ed;
$delimiter: #fff1d5;
$error: #a90707;
$blue: #05223b;

$mobileHeaderGap: -68px;
