$dayContainer: 95px;

.working_time_selects_container {
  margin-bottom: 24px;
  .working_time_selects {
    width: 100%;
    display: flex;
    align-items: center;

    .working_time_select {
      width: 100%;
      &:first-child {
        margin-right: 16px;
      }
    }
  }
}

.title-small20.mobile_title {
  font-size: 18px;
  margin-bottom: 8px;
}

.choise_working_days {
  display: flex;
  width: 100%;
  flex-direction: column;
  .select_time_container {
    width: 100%;
    align-self: stretch;
  }
  .choise_working_days_buttons {
    width: 100%;
    border-radius: 9px;
    background: $white;
    margin-bottom: 16px;
    .choise_working_days_button {
      min-height: 72px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      border: 1px solid $outline;
      border-radius: 9px;
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      text-align: left;
      letter-spacing: 0.65px;
      color: rgba($color: $grey, $alpha: 0.8);
      padding-left: 8px;
      padding-right: 0;
      .additional_button {
        position: absolute;
        top: 13px;
        right: 5px;
        .select_item {
          text-transform: initial;
        }
      }
      &.Mui-selected {
        border-color: $green;
        background-color: $white;
      }
      &.active {
        background: rgba($color: $green, $alpha: 0.1);
        color: $green;
      }
      .button_label {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 10px;
      }
      .button_time {
        font-size: 12px;
      }
    }
  }
  .not_working_day_checkbox {
    margin-bottom: 73px;
  }
  .helper_text {
    color: rgba($color: $black, $alpha: 0.6);
    margin-bottom: 16px;
  }
  .time_slider_wrapper {
    width: 100%;
    display: flex;
    position: relative;
    margin-top: 30px;
    &.spaced {
      margin-top: 70px;
    }
    .time_slider {
      width: 96%;
      align-self: center;
      &.error {
        color: $error;
      }
      &.MuiSlider-root {
        .MuiSlider-markLabelActive {
          font-size: 0.8rem;
          font-family: "Helvetica", sans-serif;
        }
      }
      .MuiSlider-valueLabelOpen {
        width: 50px;
        border-radius: 10px;
        .MuiSlider-valueLabelLabel {
          font-family: "Helvetica", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 15px;
        }
      }
    }
    .delete_time_slider_button {
      position: absolute;
      right: -20px;
      cursor: pointer;
      svg {
        fill: $error;
      }
    }
  }
  .save_time_button {
    margin-top: 32px;
    align-self: baseline;
  }
}

.mobile.choise_working_days {
  .group_wrapper {
    overflow: scroll;
    width: 100%;
    .choise_working_days_buttons {
      width: 7 * $dayContainer + 6 * 8;
      .choise_working_days_button {
        width: $dayContainer;
        min-height: 90px;
        margin-bottom: 20px;
        border-radius: 12px;
        margin-right: 8px;
        border: 1px solid $outline;
        &.Mui-selected {
          border-color: $green;
        }
        .button_label {
          font-size: 14px;
        }
        .button_time {
          letter-spacing: 0.15px;
        }
      }
    }
  }
  .not_working_day_checkbox {
    margin-bottom: 20px !important;
    margin-left: 0px;
    color: rgba(28, 28, 23, 0.6);
  }
  .save_time_button {
    width: 100%;
    margin-bottom: 24px;
  }
  .time_picker_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .time_picker {
      &.error {
        color: $error;
      }
    }
    .selected_time {
      display: flex;
      align-items: center;
      width: 100%;
      padding-bottom: 8px;
      margin-bottom: 25px;
      border-bottom: 1px solid $outline;
      .arrow {
        color: $outline;
        width: 12px;
        margin: 0 10px;
      }
      .delete_time_slider_button {
        margin: 0 0 0 auto;
        svg {
          fill: $error;
        }
      }
    }
  }
}

.delay_working_time {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid $outline;
  span {
    text-transform: none;
  }
  .title {
    display: block;
  }
  .description {
    font-weight: 400;
    color: $greyplaceholder;
  }
  .sort_filter {
    max-width: 303px;
    margin-top: 16px;
  }

  .save_time_button {
    margin-top: 32px;
    width: 132px;
  }
}

.mobile.delay_working_time {
  .save_time_button {
    margin: 32px 0;
    width: 100%;
  }
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.time_picker {
  display: flex;
  justify-items: center;
  width: 75px;
  border: none;
  outline: none;
  caret-color: rgba(0, 0, 0, 0);
  text-align: center;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.15px;
  &.react-datepicker-ignore-onclickoutside {
    color: $green;
  }
}

.react-datepicker__time-list-item--disabled,
.react-datepicker__header--time--only {
  display: none;
}

.react-datepicker__time-list-item {
  font-family: "Open Sans";
  font-style: normal;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.15px;
}

ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 38px !important;
}

.react-datepicker__time-box,
.react-datepicker__time {
  width: 115px !important;
}

.react-datepicker__time {
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
}

.react-datepicker {
  border: none;
}
