.client_location_profile_form.mobile {
  .form_row {
    margin-bottom: 16px;
  }
  .with_helper_text {
    flex-direction: column;
    margin: 0 !important;
    .select_container {
      margin-bottom: 40px;
    }
    .row_form_item {
      margin-bottom: 40px;
    }
    .area_number {
      margin-bottom: 16px;
    }
    .area_number_autocomplit_container {
      margin-bottom: 24px;
    }
  }
}
