.go_back_button_container {
  .back_button_icon {
    margin-right: 6px;
  }
  .close_button_wrapper {
    width: 100%;
    margin-bottom: 20px;
    .close_button {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border-radius: 50%;
      border: 1px solid $outline;
    }
  }
}
