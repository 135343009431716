.toggle_button_custom {
  &.MuiToggleButton-root {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    height: 40px;
    background: rgba($color: $black, $alpha: 0.08);
    border-radius: 8px !important;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: $black;
    text-transform: none;
    white-space: nowrap;
    &:hover {
      background: rgba($color: $black, $alpha: 0.18);
    }
    &.Mui-selected {
      background: $green;
      color: $whiteprimary;
      &:hover {
        background: rgba($color: $green, $alpha: 0.8);
      }
    }
    &.Mui-disabled {
      border: none;
      color: $black;
      &.Mui-selected {
        color: $whiteprimary;
      }
    }
  }
}

.toggle_button_custom_container.mobile {
  .toggle_button_custom {
    &.MuiToggleButton-root { 
      background: transparent;
      border: 1px solid $outline;
      border-radius: 16px !important;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1.25px;
      &.Mui-selected {
        background: $green;
        color: $whiteprimary;
      }
    }
  }
}