.select_container {
  position: relative;
  width: auto;
  .select_control_container {
    width: inherit;
    .select_control {
      cursor: pointer;
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: $black;
      .end_adornment_wrap {
        display: flex;
        align-items: center;
        margin-right: 22px;
      }
      .arrow_icon {
        display: flex;
        align-items: center;
        margin-right: 22px;
      }
      &.open {
        border-color: $green;
      }
      &.placeholder {
        letter-spacing: 0.25px;
        color: rgba(28, 28, 23, 0.6);
      }
      .MuiInputBase-input {
        cursor: pointer;
        text-align: left;
      }
      .icon_start {
        padding-left: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .select_body {
    position: absolute;
    top: calc(100% + 8px);
    display: flex;
    flex-direction: column;
    background: #fffefb;
    border: 1px solid #d7d3d0;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    padding: 8px;
    width: auto;
    min-width: 100%;
    max-height: 300px;
    z-index: 100;
    overflow: auto;
    overflow-x: hidden;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
    .select_item {
      display: flex;
      align-items: center;
      border: none;
      background: none;
      cursor: pointer;
      padding: 12px 16px;
      border-radius: 14px;
      text-align: left;
      text-decoration: none;
      white-space: nowrap;
      &:hover {
        background-color: $neutral;
      }
      &.disabled {
        cursor: default;
        opacity: 0.6;
        &:hover {
          background-color: transparent;
        }
      }
      .select_item_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 16px;

        [data-testId='RadioButtonCheckedIcon'] {
          color: $green;
        }
      }
      .select_item_end_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 8px;
      }
      &.match {
        color: $green;
      }
      .selected {
        font-weight: 700;
      }
    }
  }
}

.mobile_container {
  .select_body {
    overflow-y: scroll;
    max-height: 180px;
  }
}
