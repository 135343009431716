.agent_profile_focus_area_page {
  .add_focus_area_section {
    display: flex;
    align-items: flex-end;
    margin-bottom: 60px;
    .area_number_input {
      margin-right: 16px;
    }
    button {
      white-space: normal;
      line-height: 20px;
      min-width: 120px;
    }
  }
  .add_focus_area_section.mobile {
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid $outline;
    .area_number_input {
      margin-right: 0;
    }
    button {
      width: 100%;
      margin-top: 55px;
    }
  }
  .residence_type_section {
    margin-bottom: 32px;
  }
  .residence_type_section.mobile {
    margin-bottom: 16px;
  }
  .choosen_areas_section {
    .choosen_areas {
      display: flex;
      flex-wrap: wrap;
      margin-left: -8px;
      margin-right: -8px;
      .choosen_areas_item_wrap {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 8px;
        // margin-left: -8px;
        // margin-right: -8px;
        // margin-bottom: -8px;
        .choosen_areas_item {
          flex: 1;
          height: 48px;
          background: $whiteprimary;
          border: 1px solid $outline;
          border-radius: 10px;
          padding: 0 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: start;
          &:hover {
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
            transition: 0.2s;
          }
          .choosen_areas_item_text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 85%;
          }
        }
      }
    }
  }
  .choosen_areas_section.mobile {
    padding-bottom: 16px;
  }
}
