// styles for calendar container in the agent detail page
.booking_meeting_calendar_container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  border: 1px solid #d7d3d0;
  border-radius: 16px;
  margin-top: 32px;
  .filter_container {
    justify-content: space-between;
    border-bottom: 1px solid $outline;
  }
}

.select_time_modal_dialog_container {
  width: 600px;
  .modal_title {
    margin-bottom: 16px;
  }
  .description {
    text-align: center;
  }
  .modal_buttons_container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 30px;
    .modal_button {
      flex: 1;
      &:first-child {
        margin-right: 24px;
      }
    }
  }
  &.mobile {
    .modal_buttons_container {
      flex-direction: column;
      button {
        width: 100%;
        &:first-of-type {
          margin: 0 0 24px;
        }
      }
    }
  }
}

.booking_meeting_calendar_wrap {
  width: 100%;
  max-width: 1130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .bank_id_auth_wrap {
    width: 100%;
    height: calc(100% + 40px);
    position: absolute;
    top: -40px;
    left: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;
    &.agent_detail_calendar {
      top: 0px;
    }
    .top_block {
      width: 100%;
      background-color: $neutralsecond;
      border-radius: 0px 0px 16px 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 60px 0 40px;
      .bank_id_logo {
        margin-bottom: 24px;
      }
      .bank_id_auth_title {
        margin-bottom: 32px;
      }
      .login_button {
        width: 230px;
      }
    }
  }
  .bank_id_auth_wrap.mobile {
    .top_block {
      padding: 35px 0 29px;
      .bank_id_logo {
        margin-top: 0;
      }
      .bank_id_auth_title {
        text-align: center;
      }
    }
  }
  .filter_container {
    padding: 16px 30px;
  }
}
.filter_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .filter_month_section {
    display: flex;
    align-items: center;
  }
  .filter_month_today_button {
    margin-right: 33px;
  }
  .month_text {
    font-size: 20px;
    text-transform: capitalize;
  }
  .change_week_buttons {
    margin-right: 32px;
    .change_week_button {
      width: 32px;
      height: 32px;
      border-radius: 10px;
      color: $black;
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
      &:disabled {
        color: rgba($color: $black, $alpha: 0.6);
      }
    }
  }
  .address_label_container {
    display: flex;
    align-items: center;
    height: 24px;
    max-width: 600px;
    color: $black;
    .address_label_icon {
      margin-right: 8px;
      color: rgba($color: $black, $alpha: 0.8);
    }
    .address_label_areanumber {
      margin-left: 20px;
      padding-left: 20px;
      border-left: 1px solid $outline;
      color: rgba($color: $black, $alpha: 0.6);
      font-weight: 400;
    }
    .address_label_address, 
    .address_label_areanumber {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .filter_options {
    display: flex;
    align-items: center;
    margin-left: 63px;
    .filter_button {
      width: 127px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $whiteprimary;
      border: 1px solid $outline;
      border-radius: 10px;
      padding: 0 16px;
      cursor: pointer;
      transition: 0.2s;
      margin-left: 63px;
      &:hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      }
      &.open {
        border-color: $green;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      }
      .filter_button_icon {
        color: rgba($color: $black, $alpha: 0.8);
      }
    }
  }
}
.calendar_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .calendar_body_scroll_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    position: relative;

    border-top: 1px solid $outline;
    margin: 0 auto;
    .blured {
      position: absolute;
      z-index: 99;
      height: 100%;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.4);
      -webkit-backdrop-filter: blur(6px);
      backdrop-filter: blur(6px);
    }
    .calendar_body_scroll_wrap {
      width: 100%;
      max-height: 63vh;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 3px;
      }
      
      &::-webkit-scrollbar-thumb {
        background: $green; 
        border-radius: 2px 0px 0px 2px;
      }
    }
  }

    .calendar_header_container {
      display: flex;
      align-items: center;
      .change_week_button_container {
        height: 100%;
        display: flex;
        align-items: center;
        
        &:first-of-type {
          border-right: 1px solid $outline;
        }
        &:last-of-type {
          border-left: 1px solid $outline;
        }
      }
    }
  
  
  .calendar_body_container {
    display: flex;
    justify-content: stretch;
    min-height: auto;
    min-height: 30vh;
    &.calendar_body_container_empty {
      min-height: 30vh;
      .calendar_body_empty_list {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 100px;
        .empty_message { 
          text-align: center;
        }
      }
    }
    .mock_transparent_block {
      width: 41px;
      
      &:first-of-type {
        border-right: 1px solid transparent;
      }
      &:last-of-type {
        border-left: 1px solid transparent;
      }
    }
  }

  .calendar_column {
    width: 166px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid $outline;
    box-sizing: border-box;

    &:nth-of-type(5) {
      border-right: none;
    }

    &.calendar_column_header {
      height: 92px;
      justify-content: center;
      .day_number {
        &.current {
          color: $green;
        }
      }
    }

    &.calendar_column_body {
      padding-top: 12px;
      padding-bottom: 12px;
      position: relative;
    }
  }
}

.timeslot_container {
  display: flex;
  width: 100%;

  .timeslot_wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: $whiteprimary;
    border-radius: 10px;
    transition: all 0.2s;
    &.timeslot_wrap_open {
      background: $neutral;
      padding: 0 12px;
      border: 1px solid $outline;
      transition: all 0.2s;
    }
  }

  .time_slot_button {
    width: 100%;
    background: none;
    border: 1px solid $outline;
    border-radius: 10px;
    cursor: pointer;
    padding: 7px 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    transition: all 0.2s;
    &:hover {
      box-shadow: 0px 2px 5px rgba($black, 0.2);
    }
    &.time_slot_button_open {
      border: none;
      border-radius: 0;
      cursor: default;
      padding: 7px 13px;
      transition: all 0.2s;
      &:hover {
        box-shadow: none;
      }
    }
    .time_slot_time_range_text {
      font-weight: 600;
    }
  }

  &.hour_time_slot {
    padding: 4px 16px;
    transition: all 0.2s;
  }

  &.hour_time_slot_open_another {
    .timeslot_wrap {
      opacity: 0.4;
      transition: all 0.3s;
      .time_slot_button {
        cursor: default;
        &:hover {
          box-shadow: none;
        }
      }
    }
  }

  &.hour_time_slot_open {
    padding: 4px 3px;
    height: auto;
    transition: padding 0.2s;
    position: absolute;
    top: 12px;
    z-index: 10;
    .timeslots_available {
      border-top: 1px solid $outline;
      border-bottom: 1px solid $outline;
      padding: 4px 0px;
    }
    .selected_hour_time_slot_close_button {
      border: none;
      background: none;
      cursor: pointer;
      height: 44px;
      width: 100%;
      .close_button_icon {
        color: rgba($color: $black, $alpha: 0.6);
      }
    }
  }

  &.time_time_slot {
    padding: 4px 0px;
    .timeslot_wrap {
      padding: 0;
      .time_slot_button {
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          box-shadow: 0px 2px 5px rgba($black, 0.2);
          transition: all 0.3s;
        }
      }
    }
    &.selected_timeslot {
      .timeslot_wrap {
        .time_slot_button {
          border: 1px solid $green;
          box-shadow: 0px 2px 10px rgba($black, 0.12);
          background: rgba(9, 106, 94, 0.15);
        }
      }
    }
  }
}

.filter_container.mobile {
  flex-direction: column;
  margin-bottom: 10px;
  .filter_month_section {
    order: 2;
    margin-top: 24px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    .styled_button {
      margin: 0 0 0 16px;
      border-radius: 16px;
      border: 1px solid $green;
      padding: 8px 16px;
      font-size: 14px;
      line-height: 22px;
      text-transform: uppercase;
      font-weight: 600;
      height: 40px;
    }
  }
  .filter_options {
    order: 1;
    margin-left: 0;
    padding: 0 16px;
    width: 100%;
    justify-content: space-between;
    .address_label_container {
      width: 100%;
      &.agent_page {
        margin-bottom: 10px;
      }
      .address_label_address {
        margin-right: 8px;
        max-width: 50%;
      }
      .address_label_areanumber {
        padding-left: 8px;
        margin-left: 0;
        max-width: 50%;
      }
    }
    .filter_button {
      margin: 0;
      width: auto;
      height: 40px;
      padding: 0 7px;
    }
  }
  .filter_month_section {
    margin-top: 16px;
    align-items: center;
  }
  .change_week_buttons {
    margin: 0;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
    border-top: 1px solid $outline;
    padding: 16px 10px 0;
    margin-top: 24px;
    button {
      margin: 0 !important;
    }
  }
}

.mobile_container {
  border: none;
  margin: 0;
  .filter_container {
    padding: 0;
    border: none;
    .filter_options {
      padding: 0;
      border: none;
    }
    .filter_month_section {
      align-items: flex-start;
      border: none;
      .styled_button {
        margin: 0 8px 0 0;
      }
    }
    .change_week_buttons {
      padding: 16px 0;
    }
  }

  .calendar_container {
    width: 105%;
    border-bottom: 1px solid $outline;
    .calendar_body_scroll_wrap {
      max-height: 40vh;
    }
    .calendar_header_container {
      height: 60px;
    }
    .calendar_column {
      width: 100%;
      border: none;
      align-items: center;
      &.calendar_column_header {
        height: 60px;
        &::before {
          content: none;
        }
        &::after {
          width: 100%;
        }

        .day_name {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 2px;
          &.current {
            color: $green;
          }
        }
        .day_number {
          font-size: 16px;
          line-height: 24px;
          &.current {
            position: relative;
            &::after {
              content: "";
              position: absolute;
              width: 40px;
              height: 2px;
              background-color: $green;
              bottom: -5px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
    .calendar_body_container {
      .calendar_column {
        .timeslot_container {
          padding: 4px;
          .timeslot_wrap {
            .time_slot_button {
              padding: 7px 8px;
              align-items: center;
              span {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
              }
              .time_slot_time_range_text {
                font-weight: 600;
              }
              &.one_selected {
                align-items: center;
              }
            }
            &.timeslot_wrap_open {
              padding: 0;
            }
            .timeslots_available {
              .timeslot_container {
                padding: 4px 1px;
              }
              .timeslot_wrap {
                .time_slot_button {
                  padding: 7px 4px;
                }
              }
              &.one_selected {
                .timeslot_wrap {
                  .time_slot_button {
                    align-items: center;
                    padding: 7px 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .calendar_body_scroll_wrap {
      margin-right: 0;
      padding-right: 0;
    }
  }
}

.rooms {
  &.filter_form_row {
    border-bottom: none !important;
    margin-bottom: 0 !important;
  }
  .select_body {
    overflow-y: scroll;
    max-height: 180px;
  }
}

.estate {
  &.filter_form_row {
    border-bottom: none !important;
    margin-bottom: 0 !important;
  }
}
