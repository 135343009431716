.radio_button_styled {
  &.MuiFormControlLabel-root {
    margin: 0;
    margin-bottom: 8px;
  }
  .MuiFormControlLabel-label {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.15px;
    color: $black;
    margin: 0px 18px;
  }
  .MuiRadio-root {
    width: 20px;
    height: 20px;
    color: rgba($color: $black, $alpha: 0.8);
  }
}
