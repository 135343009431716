.sticky_button_wrapper {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 18px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid $outline;
  background: $whiteprimary;
  z-index: 2;
  &.isChosenAgentsList {
    bottom: 50px;
    padding: 18px 0 45px;
  }
  &.blueContainer {
    border-top: 1px solid $blue;
    background: $blue;
    bottom: 0;
    padding: 18px 0;
  }
  button {
    max-width: 327px;
    width: 100%;
  }
}
