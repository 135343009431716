.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: $black, $alpha: 0.7);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
}
