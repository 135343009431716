.client_profile_page_container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  padding: 100px 0 152px;

  .aside_wrap {
    display: flex;
    flex-direction: column;
    margin-right: 70px;
    white-space: nowrap;
    .client_profile_title {
      margin-bottom: 8px;
    }
    .client_profile_subtitle {
      margin-bottom: 50px;
    }
  }
  .subpages_wrap {
    width: 100%;
  }
  .client_profile_form {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 100%;
    max-width: 623px;
    .form_row {
      width: 100%;
      display: flex;
      margin-bottom: 32px;

      > :first-child {
        margin-right: 16px;
      }
      > :last-child {
        margin-right: 0;
      }
      &.with_helper_text {
        margin-bottom: 56px !important;
      }
      .row_form_item {
        width: 100%;
      }
    }
  }
  .disabled_inputs_notification {
    margin: -10px 0 20px;
  }
}

.mobile_container.client_profile_page_container {
  flex-direction: column;
  padding: 16px;

  @media screen and (orientation: landscape) {
    padding-bottom: 250px;
  }
}
