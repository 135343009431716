.auth_page_container {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  padding-top: 64px;
  padding-bottom: 152px;
  .auth_panels_tab {
    min-width: 178px;
  }
  .auth_panels_body_container {
    //width: 476px;
    max-height: 490px;
    .panel_item {
      display: flex;
      flex-direction: column;
      height: 100%;
      .panel_item_title {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        .restore_password_icon {
          font-size: 60px;
          margin-left: 15px;
          font-weight: 700;
        }
      }
      .panel_item_subtitle {
        margin-bottom: 40px;
      }
      .button_container {
        height: 184px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        margin-bottom: 78px;
        .button_icon {
          margin-right: 16px;
        }
        .beetwen_buttons {
          display: flex;
          align-items: center;
          width: 100%;
          .or {
            padding: 0 16px 4px;
          }
          .line {
            flex: 1;
            border: none;
            height: 1px;
            background-color: rgba($black, 0.3);
          }
        }
        .login_bankid_button {
          background: #ffffff;
          border: 1px solid rgba(120, 116, 125, 0.3);
          border-radius: 14px;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: $darkGreen;
          box-shadow: none;
        }
        .login_google_button {
          background: #ffffff;
          border: 1px solid rgba(120, 116, 125, 0.3);
          border-radius: 14px;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: $black;
          box-shadow: none;
        }
      }
      .bottom_section {
        flex: 1;
        display: flex;
        align-items: center;
        .bottom_text {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.15px;
          color: $grey;
        }
        .text_link {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.15px;
          color: $green;
          text-decoration: none;
          &.reset_password_button {
            font-weight: 600;
            padding: 0 5px;
            height: 20px;
            border-radius: 2px;
            font-size: 16px;
          }
        }
      }
      .agent_signin_form_container {
        display: contents;
      }
      .agent_sing_input {
        margin-bottom: 20px;
        &.last {
          margin-bottom: 28px;
        }
      }
      .buttons_group {
        width: 100%;
        display: flex;
      }
      .back_button {
        background-color: rgba($black, 0.3);
        color: $white;
      }

      .login_btn {
        width: 100%;
        margin-bottom: 14px;
        &.back_button {
          margin-right: 24px;
          background-color: rgba($black, 0.3);
        }
      }
    }
  }
}

.mobile_auth_page_container {
  width: 100%;
  padding: 24px 18px 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title_wrapper {
    width: 100%;
    min-height: 100px;
    margin-bottom: 10px;
    .title {
      margin-bottom: 8px;
    }
  }
  .container {
    width: 100%;
    .panel_item {
      width: 100%;
      .button_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $delimiter;
        padding: 40px 37px;
        border: 1px solid $outline;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
        border-radius: 16px;
        margin: 0 0 24px;
        .button_icon {
          margin-right: 16px;
        }
        .beetwen_buttons {
          display: flex;
          align-items: center;
          width: 100%;
          margin: 25px 0;
          .or {
            padding: 0 16px 4px;
          }
          .line {
            flex: 1;
            border: none;
            height: 1px;
            background-color: rgba($black, 0.3);
          }
        }
        button {
          width: 270px;
        }
        .login_btn {
          width: 100%;
        }
        .login_bankid_button {
          background: #ffffff;
          border: 1px solid rgba($lightGrey, 0.3);
          border-radius: 14px;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #235971;
          box-shadow: none;
          padding: 12px;
          .button_icon {
            margin-right: 10px;
          }
        }
        .login_google_button {
          background: $white;
          border: 1px solid rgba($lightGrey, 0.3);
          border-radius: 14px;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: $black;
          box-shadow: none;
          padding: 12px;
          .button_icon {
            margin-right: 10px;
          }
        }
      }
      .agent_signin_form_container {
        button {
          width: 100%;
          margin-top: 24px;
        }
      }
      .bottom_section {
        display: flex;
        justify-content: center;
        flex-direction: column;
        
        button {
          margin-top: -5px;
        }
        span {
          text-align: left;
        }
        span.support_text {
          text-align: center;
          span {
            font-weight: 400;
          }
        }
      }
      .text_link {
        color: $green;
        text-decoration: none;
        font-size: inherit;
        font-weight: 600;
      }
      .button_wrapper {
        background: $white;
        border: 1px solid $outline;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
        border-radius: 16px;
        display: flex;
        justify-content: center;
        padding: 24px 10px;
        button {
          background: rgba(28, 28, 23, 0.8);
          color: $white;
          width: 100%;
          max-width: 311px;
        }
      }
    }
  }
}
