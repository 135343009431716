.admin_auth_page {
  .row-margin-bottom {
    margin-bottom: 24px;
  }
  .login_btn {
    margin-top: 24px;
  }
  .mobile_tabs_buttons_container {
    display: none;
  }
  .mobile_form_group {
    .row-margin-bottom {
      margin-bottom: 0;
    }
  }
}