.agent_profile_page {
  display: flex;
  flex-direction: row;
  padding: 100px 0 150px;
  .aside_section {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    margin-right: 64px;
    .title_block {
      margin-bottom: 50px;
      display: flex;
      flex-direction: column;
      .title {
        margin-bottom: 8px;
      }
    }
  }
  .main_section {
    width: 100%;
    .main_section_container {
      width: 100%;
      max-width: 623px;
      &.longer {
        max-width: 674px;
      }
    }
  }
}

.agent_profile_page.mobile_container {
  flex-direction: column;
  padding: 16px;
  padding-bottom: 80px;
  .aside_section {
    margin: 0;
    .title_block {
      margin: 0;
    }
  }
}
