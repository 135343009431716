.avatar_section {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  .content_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: calc(100% - 112px);
    .content {
      display: block;
      width: 100%;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .avatar {
    margin-right: 24px;
  }
}

.avatar_section.mobile {
  padding-top: 16px;
  .content_container {
    flex-wrap: wrap;
  }
}