.card_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $dullwhite;
  border: 1px solid $outline;
  border-radius: 16px;  
  margin: 16px auto;
  padding: 0 16px;
  .button_container {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title.active {
      color: $green;
    }
    &.active {
      border-bottom: 1px solid $outline;
    }
  }
  .card_content {
    display: none;
    &.active {
      display: block;
    }
    .form_row {
      margin-top: 16px;
      &:last-child {
        margin-bottom: 16px;
      }
      &.with_help_text {
        margin-bottom: 40px;
      }
    }
  }
}

.card_wrapper.stepper {
  padding: 0 16px;
  .card_content {
    padding: 24px 0 0;
  }
  .button_container {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .check_wrapper {
      display: flex;
      align-content: center;
      .icon {
        color: $green;
      }
      .title {
        margin-left: 11px;
        font-weight: 600;
        color: $grey;
        opacity: .8;
      }
      .title.checked {
        color: $green;
        opacity: 1;
      }
    }
  }
}
