.agent_profile_connect_calendar {
  .page_title_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 16px;
    margin-bottom: 40px;
    border-bottom: 1px solid $outline;
    .title {
      margin-bottom: 8px;
    }
    .title.mobile {
      font-size: 18px;
    }
  }
  .calendar_conection_line {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 24px 0;
    .or {
      margin: 0 16px;
      color: rgba($color: $black, $alpha: 0.6);
    }
    .line {
      flex: 1;
      border-top: 1px solid $outline;
    }
  }
}

.connect_calendar_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.zoom_connect_item {
    margin-top: 30px;
  }
  .name_service_section {
    display: flex;
    align-items: center;
    .name_service_logo {
      margin-right: 16px;
      width: 50px;
      height: 50px;
    }
    .title_block {
      display: flex;
      flex-direction: column;
      .name_service_title {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 4px;
      }
    }
  }
  .google_buttons_wrapper {
    display: flex;
    position: relative;
    .select_container  {
      margin-left: 16px;
    }
    .more_google_calendar_details {
      min-width: 10px;
      width: 30px;
      padding: 16px 18px;
      span {
        margin: 0;
      }
    }

    .tooltip_message {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 16px;
      position: absolute;
      border-radius: 8px;
      background: rgba(28, 28, 23, 0.80);

      color: $white;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; 
      letter-spacing: 0.15px;

      white-space: nowrap;
      top: 0;
      left: calc(100% + 2px);
    }
  }
  .zoom_connected_done_icon {
    font-size: 40px;
    width: 145px;
  }
  .connect_calendar_item_button_wrapper {
    width: 100%;
  }
  .disabled_teams_button_hint {
    display: block;
    margin-top: 5px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.15px;
    color: #1c1c17cc;
  }
  .connect_calendar_item_button {
    width: 145px;
    .connected {
      color: $error;
    }
  }
  .more_menu_button {
    width: 40px;
    min-width: 10px;
    svg {
      margin: 0;
    }
  }
}

.mobile.connect_calendar_item {
  flex-direction: column;
  align-items: flex-start;
  .name_service_section {
    margin-bottom: 16px;
    .title_block {
      .name_service_title {
        font-size: 16px;
        margin: 0;
      }
    }
  }
  .connect_calendar_item_button {
    width: 100%;
  }
  .google_buttons_wrapper { 
    width: 100%;
  }
}

.swith_modal_dialog_container {
  padding: 48px 52px 40px;
  width: auto;
  max-width: 516px;
  .swith_modal_message {
    text-align: center;
    margin-bottom: 40px;
  }
  .modal_buttons_container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .modal_button {
      flex: 1;
      &:first-child {
        margin-right: 24px;
      }
    }
  }
  &.mobile {
    width: 100%;
    margin: 0 20px;
    padding: 28px 20px 20px;
  }
  .modal_buttons_container {
    flex-direction: column;
    .modal_button {
      &:first-child {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
  }
}

.calendar_conection_section.mobile {
  padding-bottom: 16px;
}

.administrate_calendar_modal_container {
  width: 516px;
  padding: 72px 52px 40px;
  .modal_title {
    width: 100%;
    text-align: left;
    margin-bottom: 16px;
  }
  .calendar_modal_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    .modal_message {
      width: 100%;
      margin-bottom: 16px;
    }
    .modal_calendar_container {
      width: 100%;
      max-height: 275px;
      overflow-y: scroll;
      padding: 16px 0;
      border-top: 1px solid $outline;
      border-bottom: 1px solid $outline;
      .no_calendars_title {
        padding: 100px 0;
        text-align: center;
      }
      .calendar_item {
        margin: 0 0 8px;
        border-radius: 14px;
        color: $black;
        height: 50px;
        width: 100%;
        &.administrat_calendar_item {
          span.MuiFormControlLabel-label {
            display: block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
        
        &:hover  {
          background: $neutral;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      &::-webkit-scrollbar {
        width: 3px;
      }
      
      &::-webkit-scrollbar-thumb {
        background: $green; 
        border-radius: 2px 0px 0px 2px;
      }
    }
    .modal_buttons_container {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;
      button {
        width: 48%;
      }
    }
  }
}

.administrate_calendar_modal_container.mobile {
  width: 100%;
  height: 100%;
  border-radius: 0;
  background-color: $main;
  padding: 0 16px 16px;
  overflow: auto;
  .close_button_wrapper {
    width: max-content;
  }
  .close_button {
    right: 0;
    position: relative;
  }
  .modal_title {
    width: 100%;
    text-align: left;
    margin-bottom: 16px;
  }
  .calendar_modal_wrapper {
    .mobile_title {
      margin: 21px 0 8px;
    }
    .modal_message {
      font-weight: 400;
      text-transform: none;
    }
    .modal_calendar_container {
      background: $dullwhite;
      border: 1px solid $outline;
      border-radius: 16px;
      max-height: 380px;
      .calendar_item {
        &:hover  {
          background: transparent;
        }
      }
    }
    .modal_buttons_container {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      flex-direction: column;
      align-items: center;
      padding: 16px 0;
      @media screen and (orientation: landscape) {
        position: static;
      }
        button {
          width: 100%;
          max-width: 327px;
          &:last-of-type {
            margin-top: 15px;
          }
        }
    }
  }
}
