.modal_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal_wrap {
  width: 386px;
  background: $white;
  border-radius: 20px;
  position: relative;
  padding: 72px 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .close_button {
    position: absolute;
    right: 20px;
    top: 20px;
    // width: 30px;
    // height: 30px;
    .close_button_icon {
      font-size: 34px;
      color: rgba($color: $black, $alpha: 0.8);
    }
    .mobile_close_button_icon {
      font-size: 18px;
      color: rgba($color: $black, $alpha: 0.8);
    }
  }
  .modal_title {
    margin-bottom: 32px;
    text-align: center;
  }
}
.mobile.modal_wrap {
  width: 327px;
  .modal_title {
    margin-bottom: 24px;
    text-align: center;
  }
}