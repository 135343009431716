.confirm_booking_window {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0;
  background: $blue;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 32px 32px 0px 0px;
  z-index: 100;
  border: none;
  transition: all 0.3s ease-out;
  &.open {
    height: auto;
    transition: all 0.3s ease-in;
    padding-bottom: 32px;
    .confirm_booking_container {
      height: 100%;
      opacity: 1;
      padding: 24px 20px 0;
    }
  }
  
  .confirm_window_controller {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 0 100px;
    .open_button {
      width: 120px;
      height: 6px;
      background: #fffffe;
      border-radius: 4px;
      border: none;
      outline: none;
      margin: 16px 0 8px;
      cursor: pointer;
    }
    .open_icons_container {
      cursor: pointer;
      z-index: 5;
      .icon {
        color: $white;
      }
    }
    .information_text {
      color: $white;
      cursor: pointer;
      text-align: center;
    }
  }
  
  .confirm_booking_container {
    opacity: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1130px;
    height: 0;
    align-items: flex-start;
   
    .selected_agents_card_container {
      width: 100%;
      height: 100%;
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      .agent_card_wrap {
        width: 25%;
        max-width: 199px;
        height: auto;
        display: flex;
        align-items: stretch;
        background-color: $whiteprimary;
        border-radius: 16px;
        overflow: hidden;
        .agent_card {
          display: flex;
          flex-direction: column;
          flex: 1;
          overflow: hidden;
          position: relative;
          .agent_card_foto {
            width: 100%;
            height: 200px;
            background-color: cornflowerblue;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            .agent_foto {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: top;
            }
            .no_agent_foto {
              width: 50%;
            }
          }
          .delete_button {
            position: absolute;
            right: 10px;
            top: 10px;
            width: 24px;
            height: 24px;
          }
          .agent_card_name_section {
            padding: 14px 16px;
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid $outline;

            .agent_card_name {
              letter-spacing: 0.1px;
              text-transform: none;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-bottom: 4px;
            }
            .agent_card_agency_logo {
              width: auto;
              height: 20px;
              .agency_logo {
                width: auto;
                height: 20px;
                object-fit: contain;
              }
            }
          }
          .agent_card_schedule_section {
            padding: 15px;
            display: flex;
            flex-direction: column;
            .event_date {
              letter-spacing: 0.35px;
              text-transform: uppercase;
              line-height: 18px;
              display: flex;
              align-items: center;
              color: $black;
              margin-bottom: 16px;
            }
            .event_icon {
              margin-right: 8px;
              font-size: 18px;
            }
          }
        }
        .empty_agent_card {
          .agent_card_foto {
            background-color: $main;
            .no_agent_foto {
              color: $outline;
              transform: scale(1.5);
            }
          }
          .agent_card_name {
            text-transform: none;
            cursor: pointer;
          }
          .agent_card_name,
          .event_date {
            opacity: 0.4;
          }
        }
      }
    }
  }
}

.confirm_booking_window.mobile {
  &.open {
    height: 505px;
  }

  .confirm_window_controller {
    padding: 0 10px;

  }
  .open_button {
    position: relative;
    outline: none;
    z-index: 3;
  }
  &.open {
    .confirm_booking_container {
      .selected_agents_card_container {
        height: auto;
        display: block;
        padding: 0px 0 100px;
      }
    }
  }
  overflow: scroll;
  .confirm_booking_container {
    padding-top: 10px;
    .selected_agents_card_container {
      padding: 40px 0 65px;
      .agent_card_wrap {
        width: 100%;
        flex-direction: column;
        max-width: none;
        height: max-content;
        margin-bottom: 16px;
        .agent_card {
          flex-direction: column;
          min-height: 183px;
          padding: 16px;
          &.empty_agent_card {
            padding: 0;
            flex-direction: row;
            .agent_card_foto {
              margin: 0;
              width: 96px;
              height: auto;
              border-radius: 0;
              padding-right: 30px;
            }
            .wrapper {
              justify-content: center;
              align-items: center;
              margin: 0;
            }
          }
          .agent_card_name_section {
            border: none;
            padding: 0;
            margin-bottom: 15px;
            padding-right: 10px;
            .delete_button {
              right: 8px;
              top: 8px;
            }
          }
          .agent_card_foto {
            width: 116px;
            height: 115px;
            border-radius: 16px;
            margin-right: 16px;
            .agent_foto {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
            }
          }
          .wrapper {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin: 0 0 16px;
            .main_data {
              width: calc(100% - 116px - 16px);
            }
            .agent_card_schedule_section {
              padding: 0;
              .date_wrapper {
                display: flex;
                flex-direction: column;
                .event_date {
                  margin-bottom: 8px;
                  font-size: 12px;
                  letter-spacing: normal;
                  &:last-of-type {
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.select_more_agents_modal_dialog_container {
  padding: 145px 73px 40px;
  width: auto;
  max-width: 516px;
  background-color: $white;
  background-image: url("../../assets/img/agent_modal.png");
  background-size: 348px 104px;
  background-repeat: no-repeat;
  background-position: 80px 18px;
  .modal_title_text {
    margin-bottom: 18px;
    text-align: center;
  }
  .swith_modal_message {
    text-align: center;
    margin-bottom: 40px;
  }
  .modal_buttons_container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .modal_button {
      flex: 1;
      &:first-child {
        margin-right: 24px;
      }
    }
  }
}

.mobile.select_more_agents_modal_dialog_container {
  padding: 130px 16px 0;
  background-size: 90%;
  background-position: 50% 20px;
  .swith_modal_message {
    margin-bottom: 24px;
  }
  .modal_buttons_container {
    flex-direction: column;
    .modal_button {
      margin: 0;
      display: flex;
      margin-bottom: 24px;
      &:first-of-type {
        order: 2;
      }
      &:last-of-type {
        order: 1;
      }
    }
  }
}
