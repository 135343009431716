.styled_alert {
  &.MuiAlert-root {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 100px;
    .MuiAlert-action {
      align-self: flex-start;
    }
  }
  &.MuiAlert-filled {
    background: $whiteprimary;
    border: 1px solid $outline;
    box-shadow: 0px 2px 5px rgba($color: $black, $alpha: 0.1);
    border-radius: 8px;
    color: $black;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.15px;
  }
  .MuiAlertTitle-root {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    margin-bottom: 8px;
  }
}

.SnackbarContent-root {
  max-width: 500px;
  display: flex;
  flex-wrap: nowrap !important;
}
