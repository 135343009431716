.text_input_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  .selector_container {
    position: relative;
    height: 48px;
    svg {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      fill: rgba(28, 28, 23, 0.8);
    }
  }
  .spinner_container {
    display: flex;
    justify-content: center;
    circle {
      color: rgba(28, 28, 23, 0.6);
    }
  }
  .dropdown {
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 80px;
    background: $dullwhite;
    border: 1px solid $outline;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 12%);
    border-radius: 10px;
    padding: 10px 0;
    .dropdown_item {
      padding: 7px 24px;
      &:hover {
        cursor: pointer;
      }
    }

    &::-webkit-scrollbar {
      width: 3px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: $green; 
      border-radius: 10px;
    }
    .infinite_scroll {
      max-height: 200px;
    }
  }
  .text_input_label {
    margin-bottom: 4px;
  }
  .MuiInputBase-root {
    background: $whiteprimary;
    border: 1px solid $outline;
    border-radius: 10px;
    height: 48px;

    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: $black;

    &.Mui-disabled {
      background-color: rgba($color: $outline, $alpha: 0.4);
      &:hover {
        box-shadow: none;
      }
    }

    &:hover {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    }
    &.Mui-focused {
      border-color: $green;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    }
    &.Mui-error {
      border-color: $error;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    }
    .MuiInputBase-input {
      padding: 10px 16px;
      height: 100%;
      border-radius: 10px;
      &[readonly] {
        background-color: grey;
      }
    }
  }
  .text_input_label {
    align-self: flex-start;
  }
  .text_input_text {
    position: absolute;
    left: 0;
    top: 103%;
    &.helper_text {
      color: rgba(28, 28, 23, 0.6);
    }
  }
  fieldset {
    border: none;
  }
  .MuiAutocomplete-input { 
    padding-top: 0 !important;
  }
}
