.toggle_button_group_styled {
  display: flex;
  flex-flow: row wrap;
  margin-left: -7px;
  margin-right: -7px;
  > div {
    padding: 7px;
  }
  .MuiToggleButtonGroup-grouped {
    border: none;
  }
}
