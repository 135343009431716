.agent_presentation_profile_page {
  padding: 32px 0;
  .agent_detail_container {
    margin-bottom: 30px;
  }
  .back_button {
    margin-bottom: 24px;
  }
  .agent_detail {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .agent_detail_left_container {
    width: 324px;
    margin-right: 56px;
    .phoco_camera_icon {
      width: 50px;
      height: 50px;
    }
    .agent_foto_container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: none;
      width: 100%;
      height: 340px;
      border: 1px solid $outline;
      border-radius: 24px;
      overflow: hidden;
    }
    .agent_foto {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  .agent_detail_right_section {
    display: flex;
    flex-direction: column;
    width: calc(100% - 380px);
  }
  .agent_statistic_container {
    margin-top: 32px;
    width: 100%;
    .agent_statistic_item {
      width: 100%;
      padding: 24px;
      border-radius: 15px;
      border: 1px solid $outline;
      margin-bottom: 14px;
      &.client_review {
        max-height: 400px;
        overflow-y: scroll;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          width: 3px;
        }
        
        &::-webkit-scrollbar-thumb {
          background: $green; 
          border-radius: 0 15px 0 15px;
        }
      }

      &.agent_achievements {
        max-height: 400px;
        overflow-y: scroll;
        overflow-x: hidden;
      }
      .agent_statistic_item_title {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.15px;
        color: rgba(28, 28, 23, 0.80);
        margin-bottom: 16px;
        display: block;
      }
      .statistic_item_box {
        display: flex;
        align-items: center;
        margin-bottom: 18px;
        &:last-of-type {
          margin: 0;
        }
        img {
          display: inline-block;
          margin-right: 16px;
        }

        .statistic_description {
          line-height: 24px;
          &.collapse {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
          }
        }
      }
      .rate_container {
        display: flex;
        align-items: center;
        .rate_text {
          color: rgba(28, 28, 23, 0.80);
          font-family: Open Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0.15px;
          margin-left: 5px;
        }
      }
      .statistic_container {
        margin-top: 16px;
        .statistic_value {
          color: $black;
          font-family: Open Sans;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.15px;
        }

        .statistic_description {
          color: $black;
          font-family: Open Sans;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.15px;
        }
      }
      .agent_client_feedback {
        color: $black;
        font-family: Ubuntu;
        font-size: 15px;
        font-style: italic;
        font-weight: 400;
        line-height: 150%;
      
        &.collapse {
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 7;
                  line-clamp: 7; 
          -webkit-box-orient: vertical;
        }
      }
      .collapse_button {
        display: flex;
        align-items: center;

        color: #096a5e;
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0.5px;
        margin-top: 8px;
        cursor: pointer;
      }
    }
  }
  .agent_name {
    margin-bottom: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .agent_signature_section {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    .agent_sig_foto_wrap {
      width: 100%;
      height: 50px;
      margin-bottom: 16px;
      .agent_sig_foto {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: left;
        mix-blend-mode: multiply;
      }
    }
    .agency_name {
      color: rgba($color: $black, $alpha: 0.8);
      letter-spacing: 0.65px;
    }
  }
  .agent_description_section {
    padding-top: 24px;
    border-top: 1px solid $outline;
    &.hide_text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  .contact_me_button {
    max-width: max-content;
    margin-top: 24px;
  }
  .hide_button {
    display: flex;
    align-items: center;
    color: $green;
    cursor: pointer;
    margin-top: 15px;
    width: max-content;
    span {
      color: $green;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 0.5px;
      margin-right: 5px;
    }
  }
}

.contact_me_modal_dialog_container {
  width: 980px;
  padding: 48px 58px 40px;
  align-items: flex-start;

  height: 85%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 3px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: $green; 
    border-radius: 2px 0px 0px 2px;
  }
  
  .modal_title {
    text-align: left;
    margin-bottom: 16px;
  }
  .modal_wrapper {
    padding-top: 24px;
    border-top: 1px solid $outline;
    width: 100%;
    .input_row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      &.without_bottom_space {
        margin: 0;
      }
      .text_input_container {
        &:first-of-type {
          margin-right: 24px;
        }
      }
      .select_container {
        width: 100%;
      }
      .form_row {
        width: 100%;
        &:first-of-type {
          margin-right: 24px;
        }
      }
    }

    .checkbox {
      margin-left: 0;
      margin-top: 20px;
    }
    .message_input {
      height: auto;
    }
    .modal_buttons_container {
      padding-top: 24px;
      margin-top: 10px;
      border-top: 1px solid $outline;
      .modal_button {
        width: 100%;
        max-width: 192px;
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }
  }

  &.mobile {
    padding: 40px 20px;
    height: 85%;
    .input_row {
      flex-direction: column;
      margin-bottom: 0;
      .text_input_container {
        margin-bottom: 20px;
        &:first-of-type {
          margin-right: 0;
        }
      }
      .form_row {
        width: 100%;
        &:first-of-type {
          margin-right: 0;
          margin-bottom: 20px;
        }
        .text_input_container {
          margin: 0;
        }
      }
    }
      
    .modal_buttons_container {
      width: 100%;
      margin-top: 20px;
      .modal_button {
        max-width: none;
        &:first-of-type {
          margin: 0 0 20px;
        }
      }
    }
  }
}

.agent_presentation_profile_page.mobile_container {
  padding: 32px 0px;
  margin-top: $mobileHeaderGap;
  .back_button {
    padding: 0 16px;
    .MuiButton-text {
      text-transform: uppercase;
    }
    .styled_button {
      padding-left: 0;
    }
  }
  .agent_detail {
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
    .agent_detail_left_container {
      margin: 0 0 24px;
      width: 100%;
      max-width: 400px;
      border-radius: 15px 15px 0px 0px;
      .agent_foto {
        object-fit: contain;
        object-position: center;
      }
    }
    .agent_detail_right_section {
      width: 100%;
      .agent_name {
        margin: 0;
      }
      .agent_signature_section {
        margin: 0;
        padding: 16px 0;
        .agent_sig_foto_wrap {
          height: 30px;
          margin-right: 16px;
        }
      }
      .agent_description_section {
        border-top: 1px solid $outline;
      }
      .contact_me_button {
        max-width: none;
      }
    }
  }

  .mobile_statistic_container {
    padding: 16px 0;
    margin: 16px 0;
    border-top: 1px solid $outline;
    border-bottom: 1px solid $outline;
    .statistic_wrapper {
      padding: 24px 16px;
      border-radius: 16px;
      border: 1px solid $outline;
      .more_information_button {
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        .more_information_button_title {
          color: $black;
          font-family: Open Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px; 
          letter-spacing: 0.1px;
          &.opened_info {
            color: $green;
          }
        }
      }

      .agent_statistic_container {
        margin: 0;
        &.hidden {
          display: none;
        }
        .agent_statistic_item {
          border: none;
          border-top: 1px solid $outline;
          border-radius: 0;
          padding: 16px 0 0;
          margin: 16px 0 0;

          &:first-of-type {
            margin: 24px 0 0;
          }
        }
      }
    }
  }
}
