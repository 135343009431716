.lable_checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
  a {
    color: $green;
    text-decoration: none;
    &.errored {
      color: $error;
    }
  }
  .MuiFormControlLabel-label {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: $black;
  }
  &.error {
    .MuiFormControlLabel-label {
      color: $error;
    }
  }
}

.control_checkbox {
  width: 100%;
  .wrappper {
    display: flex;
    align-items: center;
    max-width: max-content;
    .checkbox_styled {
      padding-left: 0;
    }
  }
  &.error {
    .MuiCheckbox-root,
    .MuiFormControlLabel-label {
      color: $error;
    }
  }
}
