.customer_analysis_page_container {
  .empty_state_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .empty_state {
      width: 768px;
      height: 300px;
      background: $white;
      border: 1px solid $outline;
      border-radius: 16px;
      margin: 60px auto;
      
      display: flex;
      justify-content: center;
      align-items: center;

      .empty_title {
        font-weight: 600;
        text-transform: none;
        text-align: center;
      }
    }
    .button_container {
      width: 412px;
      .form_button {
        width: 100%;
      }
    }
  }
  .wrapper {
    width: 100%;
    max-width: 768px;
    padding: 60px 0;
    margin: 0 auto;
    .notification_text {
      margin-bottom: 50px;
      .title {
        font-weight: bold;
      }
    }
    .question_section {
      width: 100%;
      background: $whiteprimary;
      border: 1px solid $outline;
      border-radius: 15px;
      overflow: hidden;
      padding-bottom: 40px;
      margin-bottom: 17px;
      &:last-of-type {
        margin: 0;
      }
      .question_section_top {
        width: 100%;
        background: $delimiter;
        padding: 16px 50px;
        margin-bottom: 40px;
        h2 {
          color: $greyplaceholder;
          text-transform: none;
        }
      }
      .question_section_container {
        width: 100%;
        padding: 0 50px;
        margin-bottom: 40px;
        &:last-of-type {
          margin: 0;
        }
        .question-title {
          margin-bottom: 8px;
        }
        .text_input {
          border: none;
          border-radius: 0;
          box-shadow: none;
          border-bottom: 1px solid rgba(120, 116, 125, 0.3);
          input {
            padding-left: 0;
          }
        }
        .checkbox_label {
          .MuiTypography-root {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: $black;
          }
        }
      }
    }
    .button_container {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: 40px;
      button {
        width: 100%;
        max-width: 412px;
      }
    }
  }
}

.customer_analysis_page_container.mobile_container {
  .empty_state_wrapper {
    padding: 15px;
    .empty_state {
      width: 100%;
    }
    .button_container {
      width: 100%;
    }
  }
  .wrapper {
    padding: 15px;
    .question_section {
      background: $main;
      border: none;
      border-radius: 0;
      padding-bottom: 32;
      margin: 0;
      .question_section_wrapper {
        background: $dullwhite;
        border: 1px solid $outline;
        border-radius: 16px;
        .question_section_container {
          padding: 24px 16px;
          margin-bottom: 0;
        }
      }
      .question_section_top {
        padding: 0;
        background: transparent;
        margin-bottom: 16px;
        h2 {
          color: $black;
        }
      }
    }
    .button_container {
      padding: 0 0 40px;
    }
  }
}