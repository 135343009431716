.client_my_meeting_page {
  padding: 64px 0 150px;
  flex-direction: row;
  justify-content: space-between;

  .title_page_block {
    width: 50%;
    flex: 0.5;
    display: flex;
    flex-direction: column;
    margin-right: 129px;
    .client_my_meeting_title {
      margin-bottom: 16px;
    }
  }
  .meetings_list {
    flex: 0.5;
    width: 50%;
    max-height: 65vh;
  }
}

.client_my_meeting_page.mobile_container {
  padding: 24px 19px;
  flex-direction: column;
  align-items: center;

  .title_page_block {
    width: 100%;
    margin: 0;
    .title {
      margin-bottom: 8px;
    }
  }
  .meetings_list {
    margin-top: 32px;
    width: 100%;
    padding: 0;
  }
}
