.auth_create_page_container {
  padding: 64px 0px 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .panel_button {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: $black;
  }
  .auth_create_back_button {
    position: absolute;
    left: 0;
  }
  .create_form_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    .control_checkbox {
      margin: 15px 10px;
      .wrappper {
        margin-bottom: 0;
        .icon {
          color: $green;
          &.error {
            color: $error;
          }
        }
      }
    }
    .create_form_title {
      margin-bottom: 8px;
    }
    .create_form_subtitle {
      margin-bottom: 40px;
    }
    .form_input {
      margin-bottom: 20px;
      width: 100%;
    }
    .form_row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .form_input {
        margin-right: 16px;
        margin-bottom: 0px;
        &.with_error {
          margin-bottom: 20px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .policy_link {
      text-decoration: none;
      color: $green;
      font-weight: 400;
      margin: 20px 0 0;
    }
    .button_icon {
      margin-right: 12px;
    }
  }
}

.auth_create_page_container.mobile_container {
  padding: 16px;
  padding-bottom: 130px;
  align-items: start;
  margin-top: $mobileHeaderGap;
  .auth_create_back_button {
    position: relative;
    left: 0;
  }
  .create_form_title {
    margin-bottom: 16px;
  }
  .control_checkbox {
    margin: -15px 10px 10px;
  }

  .form_row {
    flex-direction: column;
    background: #fffefb;
    border: 1px solid #d7d3d0;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 12%);
    border-radius: 16px;
    padding: 24px 16px;
    .form_input {
      margin: 0 0 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &:nth-last-child(-n+2) {
      margin-bottom: 0;
    }
    &.with_helper_text {
      padding-bottom: 40px;
    }
  }
  .policy_link {
    margin: 0;
  }
}
