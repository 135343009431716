.client_booking_page {
  max-width: unset;
  .filter_wrapper {
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid $outline;
  }
  .filter_container {
    max-width: 1130px;
    margin: 0 auto;
    justify-content: flex-end;
  }
  &.booking_prefill_page_container {
    //max-width: 1440px;
    max-width: 1130px;
    flex-direction: row;
    justify-content: space-between;
    padding: 64px 0 144px;
    .control_checkbox { 
      margin-left: 10px;
      .wrappper {
        margin-bottom: 0;
        .icon {
          color: $green;
          &.error {
            color: $error;
          }
        }
      }
    }
  }
  &.booking_prefill_page_container.mobile_container {
    max-width: 450px;
    flex-direction: column;
    justify-content: center;
    padding: 16px 16px 200px;
    margin-top: $mobileHeaderGap;
    .booking_form {
      display: flex;
      flex-direction: column;
      flex: 1;
      .row {
        flex-direction: column;
        &.half_row {
          width: 100%;
        }
        .row_form_item {
          width: 100%;
          &:first-child {
            margin-right: 0;
          }
          &:last-child {
            margin: 16px 0 0;
          }
        }
      }
      .policy_link {
        margin: 0 0 20px;
        font-size: 14px;
      }
      .row-margin-bottom {
        margin-bottom: 16px;
        .wrappper {
          margin-top: 16px;
          .checkbox_styled {
            padding-left: 16px;
          }
        }
        .error-text {
          padding-left: 8px;
        }
      }
    }
  }

  &.booking_filled_page_container {
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
  }
  &.booking_filled_page_container.mobile {
    padding-top: 20px;
  }
  .booking_meeting_wrap {
    display: flex;
    flex-direction: column;
  }

  .booking_page_title {
    margin-top: 40px;
    max-width: 43%;
  }
  .booking_tabs_body_container {
    min-height: 588px;
  }
  .link_container {
    width: max-content;
    display: inline-block;
    height: 40px;
    margin-bottom: 20px;
  }
  .booking_form {
    display: flex;
    flex-direction: column;
    flex: 1;
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &.half_row {
        width: 50%;
      }
      .row_form_item {
        flex: 1;
        &:first-child {
          margin-right: 16px;
        }
      }
      .policy_link {
        text-decoration: none;
        color: $green;
        font-weight: 400;
        margin: 20px 0 32px;
      }
    }
    .form_title {
      margin-bottom: 40px;
    }

    .bootom_buttons_container {
      flex: 1;
      width: 100%;
      display: flex;
      align-items: flex-end;
    }
    .next_button {
      width: 100%;
      .button_icon {
        margin-left: 10px;
      }
    }

    .disabled_inputs_notification {
      margin: -10px 0 10px;
      &.topSpace {
        margin: 20px 0;
      }
    }
  }
  .row-margin-bottom {
    margin-bottom: 24px;
  }
  .row-margin-bottom-half {
    margin-bottom: 10px;
  }
}

.address_absent_modal_container {
  padding: 48px 60px 40px;
  width: 517px;
  .calendar_modal_wrapper {
    .description {
      display: block;
      text-align: center;
    }
    .modal_button {
      width: 100%;
      margin-top: 40px;
    }
  }
  &.mobile {
    width: 100%;
    margin: 0 20px;
    padding: 40px 25px;
  }
}

.user_data_updates_modal_container {
  padding: 48px 60px 40px;
  width: 700px;
  .calendar_modal_wrapper {
    width: 100%;
    .description {
      display: block;
      text-align: center;
    }
    .modal_button {
      width: 100%;
      margin-top: 40px;
    }
    .buttons_container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      button {
        max-width: 277px;
      }
    }
  }
  &.mobile {
    width: 100%;
    margin: 0 20px;
    padding: 40px 25px;
    .buttons_container {
      flex-direction: column;
      justify-content: center;
      button {
        max-width: 277px;
        margin-top: 16px;
        &:first-of-type {
          order: 2;
        }
        &:last-of-type {
          order: 1;
        }
      }
    }
  }
}
