$mobile-header-height: 62px;

// desktop styles
.header {
  width: 100%;
  height: 68px;
  border-bottom: 1px solid $outline;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  top: 0;
  background-color: $main;
  .header_container {
    width: 100%;
    height: 68px;
    max-width: 1130px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .menus_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > *:first-child {
        margin-right: 24px;
      }
      .menu_button_links {
        cursor: pointer;
      }
      .links_menu_container {
        width: 203px;
        .select_item {
          width: 100%;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: $black;
          &.end {
            justify-content: space-between;
          }
        }
        .subtitles {
          .select_item {
            color: $grey;
            letter-spacing: 0.15px;
            text-transform: none;
            padding-left: 25px;
          }
        }
      }
    }
    .nav_menu_container {
      .nav_list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-direction: row;
        .nav_list_item {
          margin-right: 24px;

          &:last-child {
            margin-right: 0;
          }
          .nav_list_item_link {
            text-decoration: none;
            padding: 8px 16px;
            &:hover {
              background-color: rgba($green, 0.15);
              border-radius: 16px;
            }
            &.active {
              background-color: $green;
              border-radius: 16px;
              color: $white;
              &:hover {
                background-color: $green;
              }
            }
          }
        }
      }
    }
    .nav_list_item_link {
      text-decoration: none;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.profile {
        border: 1px solid #d7d3d0;
        border-radius: 16px;
        &:hover {
          background-color: rgba($green, 0.15);
        }
      }
      &.active {
        background-color: $green;
        border-radius: 16px;
        color: $white;
        &:hover {
          background-color: $green;
        }
      }
    }
    .header_profile_button {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: $black;
      padding-left: 16px;
      padding-right: 16px;
      &.open {
        background: rgba(9, 106, 94, 0.15);
      }
    }
    .button_icon {
      margin-right: 9px;
      width: 18px;
      height: 18px;
    }
    .test_active {
      color: $green;
    }
  }
}

// mobile styles
.mobile_header_wrapper {
  position: fixed;
  z-index: 101;
  width: 100%;
  margin-top: $mobileHeaderGap;
  background-color: $main;
  .mobile_header {
    width: 100%;
    position: relative;
    z-index: 101;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .mobile_header_container {
      width: 100%;
      height: $mobile-header-height;
      border-bottom: 1px solid $outline;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px 0 16px;
      .burger_button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        .icon {
          color: $black;
        }
      }
    }
  }
  .mobile_dropdown_wrapper {
    position: fixed;
    // calc full screen width minus header height
    //height: 100vh;
    width: 100%;
    .mobile_dropdown_menu {
      position: absolute;
      z-index: 100;
      left: 0;
      top: 0;
      width: 100%;
      background-color: $main;
      overflow-y: scroll !important;
      div {
        // change the custom setting in the imported AnimateHeight component
        display: block !important;
      }
      .nav_menu_container {
        overflow-y: scroll;
        overflow-x: hidden;
        padding-bottom: 100px;
        .nav_list {
          margin: 0;
          padding: 0;
          list-style-type: none;
          display: flex;
          flex-direction: column;
          @media screen and (orientation: landscape) {
            padding-bottom: 100px;

            height: calc(100vh - 168px);
            overflow: hidden auto;
          }
          .nav_list_item {
            .nav_list_item_link {
              height: 57px;
              text-decoration: none;
              padding: 8px 16px 8px 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid #d7d3d0;
              margin-left: 16px;
              &.full {
                width: 100%;
                display: flex !important;
                align-items: center;
                justify-content: space-between;
                padding-right: 20px;
                span {
                  display: block;
                }
              }
              .icon {
                margin-right: 14px;
              }
            }
          }
          .subtitles {
            font-size: 14px;
            padding-left: 20px;
            .custom_link {
              color: $grey;
              text-transform: none;
              letter-spacing: 0.15px;
            }
          }
        }
      }
      .footer {
        left: 0;
        bottom: 0;
        background-color: #fff1d5;
        height: 89px;
        padding-top: 17px;
        .footer_wrapper {
          width: 100%;
          display: flex !important;
          align-items: center;
          justify-content: space-between;
          padding: 0 24px 0 18px;
          cursor: pointer;
        }
        .account {
          display: flex !important;
          align-items: center;
          .log {
            text-transform: uppercase;
          }
          .img {
            margin-right: 18px;
          }
        }
      }
    }
  }
}
