.table_container {
  width: 100%;
  max-height: 86vh;
  border-radius: 16px;
  border: 1px solid $outline;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  .table {
    width: 100%;
    background: $whiteprimary;
    border-radius: 16px;
    border-collapse: separate;
    border-spacing: 0;
    .table_row {
      height: 80px;

      &:last-child {
        .table_cell {
          border-bottom: none;
        }
      }
      &.empty_table_row {
        height: 240px;
        .empty_table_cell {
          color: rgba($color: $black, $alpha: 0.6);
        }
      }

      .table_cell {
        text-align: left;
        padding-right: 40px;
        border-bottom: 1px solid $outline;
        &:first-child {
          padding-left: 40px;
        }
        button {
          cursor: pointer;
        }
        .table_cell_wrap {
          display: flex;
          align-items: center;
          background: none;
          padding: 0;
          margin: 0;
          outline: none;
          border: none;
          font-family: inherit;
          font-style: inherit;
          font-weight: inherit;
          font-size: inherit;
          line-height: inherit;
          letter-spacing: inherit;
          color: inherit;
          text-align: inherit;
          .sort_icons {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            .icon {
              width: 18px;
              height: auto;
              padding: 0;
              margin: 0;
              &.up_icon {
                margin-bottom: -7px;
              }
              &.active {
                color: $black;
              }
            }
          }
        }
      }
    }
    .table_header {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 1;
      .table_row {
        background: $delimiter;
        height: 54px;
        .table_cell {
          color: rgba(28, 28, 23, 0.6);
          border-bottom: 1px solid $outline;
        }
      }
    }
  }
}
