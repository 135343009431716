.footer {
  width: 100%;
  //height: 300px;
  background-color: $neutralsecond;
  display: flex;
  justify-content: center;
  .footer_container {
    padding: 40px 0 24px;
  }
  .top_section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid rgba(120, 116, 125, 0.3);
    padding-bottom: 40px;
    .logo_section {
      display: flex;
      flex-direction: column;
      .logo {
        margin-bottom: 24px;
      }
    }
    .right_block {
      display: flex;

      .contacts_section,
      .navigation_section {
        display: flex;
        flex-direction: column;
      }
      .navigation_section {
        margin-right: 140px;
      }
    }
  }
  .bootom_section {
    padding-top: 32px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .block_title {
    font-weight: 700;
    letter-spacing: 0.45px;
    margin-bottom: 15px;
  }
  .block_content {
    color: $black;
    letter-spacing: 0.45px;
  }
  .bottom_section_content {
    margin-right: 10px;
  }
  .footer_nav_link {
    margin-bottom: 15px;
    font-weight: 400;
    letter-spacing: 0.45px;
    text-decoration: none;
    &:last-child {
      margin-bottom: 0;
    }
    &.email_link {
      display: flex;
      align-items: center;
      .email_link_icon {
        margin-right: 10px;
      }
    }
  }
}

.mobile_footer {
  width: 100%;
  background-color: $neutralsecond;
  display: flex;
  justify-content: center;
  .footer_container {
    padding: 40px 16px 32px
  }
  .top_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgba(120, 116, 125, 0.3);
    padding-bottom: 24px;
    .logo_section {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 32px;
      .footer_button {
        width: 100%;
      }
      .logo {
        margin-bottom: 24px;
      }
    }
    .right_block {
      display: flex;
      width: 100%;
      .contacts_section {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  .bootom_section {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .block_title {
    font-weight: 700;
    letter-spacing: 0.45px;
    margin-bottom: 15px;
    display: block;
    width: 100%;
  }
  .block_content {
    color: $black;
    letter-spacing: 0.45px;
  }
  .bottom_section_content {
    margin-right: 10px;
  }
  .footer_nav_link {
    margin: 24px 0;
    font-weight: 400;
    letter-spacing: 0.45px;
    text-decoration: none;
    &:last-child {
      margin-bottom: 0;
      margin-left: 10px;
    }
    &.email_link {
      display: flex;
      align-items: center;
      .email_link_icon {
        margin-right: 10px;
      }
    }
  }
}


