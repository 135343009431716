@import-normalize;

@import "variables";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Open Sans", "Ubuntu", sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  background-color: $main;
  min-height: 100vh;
  overflow: hidden;
  padding-top: 68px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.page_container {
  display: flex;
  flex-direction: column;
  max-width: 1130px;
  width: 100%;
}

.mobile_container {
  max-width: 450px;
  margin: 0 auto;
}

.mobile_form_group {
  background: $dullwhite;
  border: 1px solid $outline;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: 24px 16px;
  margin: 0 0 16px;
  .buttons_group {
    display: flex;
    flex-direction: column;
    .back_button {
      order: 2 !important;
      display: flex;
      background: rgba(28, 28, 23, 0.8);
      color: $white;
    }
    .submit_button {
      order: 1;
      display: flex;
    }
  }
  
  .text_input_container {
    margin-top: 16px;
  }
  .text_input_container:first-child {
    margin-top: 0;
  }
}

@import "main";

.main-header {
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 80px;
  color: $black;
}

.medium-header {
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  color: $black;
}

.small-header {
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 46px;
  color: $black;
}

.title-large {
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  color: $black;
}

.title-medium {
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: $black;
}

.title-small {
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: $black;
}

.title-small-bold {
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: $black;
}

.title-small20 {
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: $black;
}

.title-small20-bold {
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: $black;
}

.label-bold {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: $black;
}

.label-medium {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.65px;
  text-transform: uppercase;
  color: $black;
}

.label-small {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: $black;
}

.error-text {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: $error;
}

.paragraph-large {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: $grey;
}

.paragraph-medium {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.15px;
  color: $grey;
}

.paragraph-body {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: $grey;
  &::placeholder {
    font-weight: 400;
    letter-spacing: 0.25px;
    color: $greyplaceholder;
  }
}

.paragraph-body-small {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: rgba($color: $black, $alpha: 0.8);
}

.paragraph-body-bold {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: $black;
  &::placeholder {
    font-weight: 400;
    letter-spacing: 0.25px;
    color: $greyplaceholder;
  }
}

.note {
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.45px;
  color: $black;
}

.navigation-link {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: $black;
}

.text-link-large {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.15px;
  text-decoration-line: underline;
  color: $black;
}

.text-link-medium {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: $black;
  &.active {
    font-weight: 700;
  }
}

.link-paragraph {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-decoration-line: underline;
  color: $black;
}

.profile_link {
  text-decoration: none;
  margin-bottom: 15px;
}

.label-extra-small {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.35px;
  color: $black;
}

.mobile_container .error-text {
  font-size: 12px;
}

.mobile_container .text_input_text {
  top: 100%;
}
