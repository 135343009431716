// common styles for meeting list (seller, agent card)
.meeting_list_container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  align-items: unset;

  .meeting_list {
    overflow: auto;
    padding: 0 30px 0 10px;
    margin-right: -20px;
    .empty_list_container {
      width: 100%;
      border: 1px solid $outline;
      height: 125px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      .empty_list_text {
        color: rgba($black, 0.7);
        text-align: center;
      }
    }
  }
  .shadow_overlay {
    position: absolute;
    width: 100%;
    height: 90px;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 249, 237, 0) 0%,
      #fff9ed 80.4%
    );
  }
  .meeting_item {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    &.today {
      border-color: $green;
    }
    .row {
      display: flex;
      align-items: center;

      &.title_section {
        margin-bottom: 16px;
        justify-content: space-between;
        .title {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 60%;
          &.agent_title {
            text-transform: uppercase;
            width: 80%;
          }
        }
        &.special_gap {
          margin-bottom: 8px;
        }
      }
      .subtitle {
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.65px;
        text-transform: uppercase;
        color: rgba($color: $black, $alpha: 0.8);
        &.today_subtitle {
          color: #096a5e;
        }
      }
    }
    .line {
      background-color: #D7D3D0;
      width: 100%;
      height: 1px;
      margin: 16px 0;
    }
    .location_item {
      display: flex;
      align-items: center;
      margin-right: 20px;
      overflow-x: hidden;
      .icon {
        margin-right: 10px;
        color: $green;
        &.block {
          display: flex;
        }
      }
      .location_item_text {
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: $black;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        &.standart {
          text-transform: none;
        }
      }
      .location_container {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .street {
          margin-bottom: 8px;
        }
      }
      &.baseline {
        align-items: start;
        margin-top: 16px;
      }
    }
    .mini_location_item_container {
      .mini_location_item {
        text-align: right;
        display: block;
        .distance {
          text-transform: uppercase;
          color: $green;
          font-weight: 600;
        }
        .time {
          text-decoration: underline;
          color: $green;
          font-weight: 600;
        }
      }
    }
    
    .additional_menu {
      margin-right: 0;
      margin-left: auto;
      .cancel_button {
        color: $error;
      }
      .dots {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.meeting_list_container.mobile {
  .meeting_list {
    padding: 0;
    margin: 32px 0 0;
    .white_wrap_block {
      padding: 24px 16px;
      .title_section {
        overflow: visible;
        .location_item {
          &.baseline {
            margin-right: 0;
          }
        }

        .title {
          width: 100%;
          &.agent_title {
            font-size: 20px;
          }
        }
      }
      .date_range_wrapper {
        position: relative;
      }
      .date_range {
        font-size: 12px;
        text-decoration: underline;
      }
      .top_range {
        position: absolute;
        right: 0;
        top: -15px;
        text-decoration: none;
      }
      .isSellerRange {
        text-decoration: none;
      }
      .location_section {
        .location_item {
          margin: 0;
          margin-right: 10px;
          &.baseline {
            margin-right: 0;
          }
          &:first-child {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

// styles for seller meetings card -> delete modal
.delete_meeting_modal_container {
  width: 516px;
  padding: 40px 70px;
  .modal_wrapper {
    width: 100%;
    .modal_buttons_container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      button {
        width: 100%;
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }
  }
}

.delete_meeting_modal_container.mobile {
  padding: 24px;
  .modal_wrapper {
    .modal_buttons_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      button {
        width: 100%;
        &:first-of-type {
          margin: 0 0 24px;
        }
      }
    }
  }
}
