.thank_you_page_container {
  max-width: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 200px;
  .title {
    margin-bottom: 16px;
    text-align: center;
  }
  .description {
    font-weight: 600;
    margin-bottom: 24px;
    text-align: center;
  }
  .meeting_cards_container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 24px 0;
    border-top: 1px solid $outline;
    border-bottom: 1px solid $outline;
    .meeting_card {
      width: 200px;
      display: flex;
      flex-direction: column;
      background: $white;
      border-radius: 16px;
      margin: 8px;
      .information_container {
        display: flex;
        flex-direction: column;
        padding: 16px;
      }
      .agent-information {
        span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;      
        }
        .agent {
          margin-bottom: 12px;
          color: $black;
        }
        .agency {
          text-transform: uppercase;
          font-weight: 600;
          color: $black;
        }
      }
      .meeting-information {
        align-items: flex-start;
        .meeting_item {
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            letter-spacing: 0.35px;
          }
          &.date {
            margin-bottom: 16px;
          }
          .icon {
            margin-right: 7px;
          }
        }
      }
      .line {
        border-top: 1px solid $outline;
        width: 100%;
      }
    }
  }
  .subtitle {
    padding-top: 60px;
  }
  .subdescription {
    padding: 16px 0 32px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #1C1C17;
  }
  .buttons_container {
    a {
      margin-left: 16px;
    }
  }
}

.thank_you_page_container.mobile_container {
  padding: 30px 16px 50px;
  .description,
  .subdescription {
    font-weight: 400;
    text-align: center;
  }
  .subtitle {
    width: 100%;
    text-align: center;
    padding-top: 30px;
  }
  .meeting_cards_container {
    padding: 16px 5px;
    .meeting_card {
      width: 100%;
    }
  }
  .information_container {
    span {
      text-align: center;
    }
  }
  .meeting_cards_container {
    .meeting_card {
      .meeting-information {
        flex-direction: row;
        justify-content: center;
        .meeting_item {
          margin: 0 8px;
          span {
            font-size: 12px;
          }
          &.date {
            margin-bottom: 0;
          }
        }
      }
      .agent-information {
        .agent {
          font-weight: 500;
          margin-bottom: 8px;
        }
      }
    }
  }
  .buttons_container {
    display: flex;
    flex-direction: column;
    width: 100%;
      button {
        width: 100%;
        order: 2;
        margin-top: 16px;
      }
      a {
        width: 100%;
        margin: 0;
      }
  }
}