.agent_meeting_page {
  padding: 0 0 150px;

  .meeteng_page_bottom_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    .bottom_section_title {
      margin-bottom: 30px;
    }
    .metting_list {
      width: auto;
      display: flex;
      flex-wrap: wrap;
      margin-left: -40px;
      margin-right: -40px;
      .empty_list_container {
        width: 100%;
        padding-top: 80px;
        display: flex;
        justify-content: center;
        .empty_list_text {
          color: rgba($black, 0.7);
          text-align: center;
        }
      }
      .item_container {
        padding: 0 40px 40px;
      }
      .meeting_item {
        width: 320px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .title_section {
          margin-bottom: 16px;
        }
        .meeting_item_date {
          margin-bottom: 8px;
        }
        .meeting_item_title {
          font-family: "Ubuntu", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 26px;
          color: $black;
          margin-bottom: 18px;
        }
        .location_item {
          .location_item_text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .location_container {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            .street {
              margin-bottom: 8px;
            }
          }
          &.baseline {
            align-items: start;
            margin-top: 16px;
          }
        }
        .meeting_item_button {
          width: 100%;

          .button_text {
            margin-right: 10px;
          }
        }
      }
    }
    .show_more_button {
      align-self: baseline;
    }
  }

  .meeteng_page_top_section {
    width: 100%;
    max-height: 90vh;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $outline;
    padding-top: 100px;
    padding-bottom: 55px;
    margin-bottom: 40px;
    .top_section_item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 0.5;
      width: 50%;
      &:first-child {
        margin-right: 129px;
      }
      &.left_block {
        .page_title {
          margin-bottom: 15px;
        }
        .page_description {
          margin-bottom: 30px;
        }
      }
      &.right_block {
        position: relative;
        overflow: hidden;
        align-items: unset;
      }
    }
  }

  .meeteng_page_top_section.mobile {
    padding: 24px 19px;
    flex-direction: column;
    max-height: none;
    border-bottom: none;

    .top_section_item {
      width: 100%;
      margin: 0;

      .page_title {
        font-size: 32px;
        line-height: 42px;
      }

      .page_description {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 24px;
      }

      button {
        margin: 0 auto;
      }
    }
  }

  .location_item {
    display: flex;
    align-items: center;
    margin-right: 20px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .icon {
      margin-right: 10px;
      color: $green;
    }
    .location_item_text {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: $black;
    }
  }

  .row {
    display: flex;
    align-items: center;
  }

  .line {
    background-color: #D7D3D0;
    width: 100%;
    height: 1px;
    margin: 16px 0;
  }
}

.agent_meeting_page.mobile_container {
  padding: 0;
  .location_item {
    margin-right: 10px;
  }
}
