.profile_avatar_block_container {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .avatar {
    margin-right: 24px;
  }
  .button {
    .uploa_icon {
      margin-right: 12px;
    }
    .trash_icon {
      margin-left: 12px;
    }
    &.upload_button {
      margin-right: 16px;
    }
  }
}
.profile_avatar_block_container.mobile {
  flex-direction: column;
  padding: 16px 0 30px;
  position: relative;
  margin-bottom: 17px;
  border-bottom: 1px solid $outline;
  .avatar {
    margin: 0 0 20px;
  }
  .button {
    .uploa_icon {
      margin-right: 0;
    }
    .trash_icon {
      margin-left: 0;
    }
    &.upload_button {
      margin-right: 0;
      width: 164px;
    }
  }
  .overlay_delete_button {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: $grey;
    border: 1px solid $outline;
    border-radius: 50%;
    backdrop-filter: blur(4px);
    transform: translate(45px, 10px);
    .icon {
      width: 15px;
      color: $whiteprimary;
    }
  }
}
.profile_form_block_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  ///max-width: 623px;
  .names_section {
    display: flex;
    margin-bottom: 30px;
    .names_input {
      flex: 1;
      &:first-child {
        margin-right: 16px;
      }
    }
  }
  .row_inputs {
    margin-bottom: 32px;
    &.title {
      margin-bottom: 60px;
    }
    &.presentation {
      height: auto;
      margin-bottom: 60px;
      .presentation_input {
        height: auto;
      }
    }
  }
  .accomplishments_input {
    margin-bottom: 16px;
  }
  .buttons_section {
    display: flex;
    .save_button {
      margin-right: 24px;
    }
  }
}

.profile_form_block_container.mobile {
  .names_section { 
    flex-direction: column;
    margin: 0 0 16px;
    .names_input {
      flex: 1;
      &:first-child {
        margin: 0 0 16px;
      }
    }
  }
  .row_inputs {
    margin-bottom: 16px;
    &.title {
      margin-bottom: 45px;
    }
    &.presentation {
      margin-bottom: 60px;
    }
  }
  .buttons_section {
    display: flex;
    justify-content: center;
    .save_button {
      margin: 0;
    }
    .preview_button {
      margin-bottom: 24px;
    }
  }
}
