.crop_image_modal {
  .crop_image_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    max-height: 100vh;
    width: 100%;
    button {
      margin-top: 20px;
      align-self: flex-start;
    }
  }

  .img-preview {
    overflow: hidden;
  }

  .box {
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
  }
}
