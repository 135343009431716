.input_label_text {
  display: inline-block;
  position: relative;
  margin-bottom: 4px;

  .requared_icon {
    position: absolute;
    left: calc(100% + 4px);
    top: 0;
  }
}

.mobile_container {
  .input_label_text.requared {
    &::after {
      content: '*';
      position: absolute;
      color: $error;
      padding-left: 5px;
      font-weight: 200;
    }

  }
  .requared_icon {
    display: none;
  }
}
