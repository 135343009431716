.custom_slider {
  &.MuiSlider-root {
    .MuiSlider-rail {
      background: transparent;
    }
    .MuiSlider-thumb {
      width: 16px;
      height: 16px;
      background-color: $green;
    }
    .MuiSlider-track {
      height: 2px;
      background-color: $green;
    }
    .MuiSlider-mark {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $green;
    }
  }
}
