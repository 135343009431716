.styled_button {
  text-transform: none;
  white-space: nowrap;
  min-width: auto;
  position: relative;
  overflow: hidden;
  > svg:first-child {
    margin-right: 10px;
  }
  &.MuiButton-containedPrimary {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: $white;
    border: 1px solid rgba(120, 116, 125, 0.3);
    text-transform: none;
    box-shadow: none;
    &.Mui-disabled {
      color: $white;
      box-shadow: none;
      background: rgba($color: $green, $alpha: 1);
      filter: grayscale(1) brightness(1.3);
    }
  }
  &.MuiButton-outlinedPrimary {
    border: 1px solid $outline;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: $black;
    padding: 0 20px;
    text-transform: none;
    box-shadow: none;
  }
  &.MuiButton-textPrimary {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: $green;
    text-transform: none;
    height: auto;
    border-radius: unset;
  }
  &.Large {
    height: 56px;
    border-radius: 14px;
    padding: 16px 20px;
    font-size: 18px;
  }
  &.Medium {
    height: 48px;
    border-radius: 10px;
    font-size: 16px;
    padding: 13px 16px;
  }
  &.Small {
    height: 42px;
    border-radius: 8px;
    font-size: 16px;
    padding: 10px 12px;
  }
  &.Small-round {
    height: 38px;
    border-radius: 16px;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }

  .button_spinner_wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: $black, $alpha: 0.4);
  }
}
